import React from 'react'

import { Link, List, ListItem, Paragraph } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMock } from '../../components/LinkMock'
import { Section } from '../../components/Section'
import IconHTMLExample from '../../examples/html/IconHTMLExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Link"
    components={[{ component: Link, restElement: true }]}
    status={[{ type: 'accessible', version: '14.4.0' }]}
  >
    <Section>
      <Playground>
        <Paragraph noMargin>
          Linkin käyttö <Link as={LinkMock}>leipätekstin</Link> kanssa.
        </Paragraph>
      </Playground>
      <Playground format="html">
        <p className="mb-0">
          Linkin käyttö <LinkMock>leipätekstin</LinkMock> kanssa.
        </p>
      </Playground>
    </Section>

    <Section title="Palvelun ulkopuolelle johtava linkki">
      <List variant="unordered">
        <ListItem>
          Ulkoisen linkin nimestä tulee käydä ilmi, minne linkki johtaa
          (linkkitekstinä esim. palvelun nimi tai url). Ulkoisia linkkejä ei
          merkitä <Code>external-link</Code>-ikonilla, elleivät ne aukea uuteen
          välilehteen.
        </ListItem>
      </List>
    </Section>
    <Section title="Uudelle välilehdelle aukeava linkki">
      <List variant="unordered">
        <ListItem>
          Uudelle välilehdelle aukeava linkki merkitään{' '}
          <Code>external-link</Code>-ikonilla. Ikonia käytetään vain, jos linkki
          avautuu uuteen välilehteen. Ikonilla ei siis merkitä ulkoisia
          linkkejä, elleivät ne aukea uuteen välilehteen.
        </ListItem>
        <ListItem>
          Linkki avataan uuteen välilehteen vain, jos linkin avaaminen katkaisee
          flown. Uuteen välilehteen avattavia linkkejä ovat esim.
          tietosuojaseloste, käyttöehdot, käyttöohjeet. Jos linkki on kiinteä
          osa flowta (esim. tunnistautuminen), sitä ei avata uuteen välilehteen.
        </ListItem>

        <ListItem />
      </List>
      <Playground>
        <Paragraph noMargin>
          Linkin käyttö{' '}
          <Link
            href="https://www.google.com"
            external
          >
            leipätekstin
          </Link>{' '}
          kanssa.
        </Paragraph>
      </Playground>
      <Playground format="html">
        <p className="mb-0">
          Linkin käyttö{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.google.com"
          >
            leipätekstin
            <IconHTMLExample
              ariaLabel="Avautuu uuteen välilehteen"
              baseline
              className="link-external-icon"
              name="ExternalLinkIcon"
            />
          </a>{' '}
          kanssa.
        </p>
      </Playground>
    </Section>
    <Section title="Linkki tummalla pohjalla">
      <Playground isDark>
        <Paragraph noMargin>
          Lorem ipsum{' '}
          <Link
            as={LinkMock}
            isOnDark
          >
            dolor
          </Link>{' '}
          sit amet
        </Paragraph>
      </Playground>
      <Playground
        format="html"
        isDark
      >
        <p className="mb-0">
          Lorem ipsum <LinkMock className="link-on-dark">dolor</LinkMock> sit
          amet
        </p>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Linkin pitää lähtökohtaisesti olla aina alleviivattu. Tiedon
          ilmaiseminen pelkällä värillä ei täytä saavutettavuusvaatimuksia.
        </ListItem>
        <ListItem>
          Jos linkki siirtää käyttäjän ulkopuoliselle sivustolle, tai avautuu
          uuteen välilehteen, siitä pitää kertoa käyttäjälle.
        </ListItem>
        <ListItem>
          Varmista, että linkin kohde on selkeästi ymmärrettävissä linkin
          tekstistä. Älä käytä yleisiä ja epämääräisiä tekstejä, kuten &quot;lue
          lisää&quot;.
        </ListItem>
        <ListItem>
          Jos linkissä on kuva tai ikoni, sille pitää tarjoa alt-teksti. Ikonin
          tai kuvan pitää olla saman värinen kuin linkkiteksti.
        </ListItem>
      </List>
      <Paragraph>
        Yleinen periaate on, että <Code>Link</Code>-komponenttia käytetään, kun
        käyttäjä ohjataan toiselle sivulle, <Code>Button</Code>ia puolestaan
        käytetään toiminnon yhteydessä.
      </Paragraph>
      <Paragraph>
        Käyttäjän on tärkeää hahmottaa, milloin siirrytään pois palvelusta.
        Linkkien nimeämistä on käsitelty kohdassa{' '}
        <a href="#palvelun-ulkopuolelle-johtava-linkki">
          Palvelun ulkopuolelle johtava linkki
        </a>
        .
      </Paragraph>
      <Paragraph>
        Erityisesti ruudunlukijan käyttäjän on tärkeää tietää etukäteen, jos
        linkki avautuu uuteen välilehteen. Välilehden vaihtuminen ilman
        varoitusta voi hämmentää käyttäjää, koska uudelta välilehdeltä ei pääse
        palaamaan takaisin edelliselle sivulle selaimen Back-painikkeella, vaan
        vaihtamalla välilehteä. Uuteen välilehteen avautumista koskeva ohjeistus
        on kohdassa{' '}
        <a href="#uudelle-valilehdelle-aukeava-linkki">
          Uudelle välilehdelle aukeava linkki
        </a>
        .
      </Paragraph>
    </Section>
  </Content>
)

export default Page
